<template>
  <div>
   <b-overlay :show="loading" >
     <div class="w-100 d-flex justify-content">
       <b-button variant="warning" @click="toBack">
         {{$t('message.back')}}
       </b-button>
     </div>

     <ValidationObserver ref="refFormObserver">
       <b-card class="mt-2" v-if="!$route.params.id && !$route.params.tourId">
         <!-- Client  -->
         <b-row>
           <b-col md="12">
             <h4>
               {{$t('message.client_text')}}
             </h4>
           </b-col>

           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.passport')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.passport')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="book"></b-icon>
                   <input
                       class="form-control"
                       style="width: 50px;"
                       v-model="clientModel.passport_serial"
                       maxlength="2"
                       placeholder="AA"
                       type="text"
                       @input="toLoware();changePassort()"
                   />
                   <input
                       class="form-control"
                       ref="passportNumber"
                       v-model="clientModel.passport_number"
                       placeholder="1234567"
                       v-mask="'#######'"
                       type="number"
                       @input="changePassort"
                   />
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>

           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.given_date')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.given_date')}}
                 </p>
                 <div class="wrapper-form">
                   <!-- <b-icon icon="calendar2-check"></b-icon> -->
                   <input type="date" class="form-control" v-model="clientModel.given_date">
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>

           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.expire_date')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.expire_date')}}
                 </p>
                 <div class="wrapper-form">
                   <!-- <b-icon icon="calendar2-check"></b-icon> -->
                   <input type="date" class="form-control" v-model="clientModel.expire_date" >
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>

           <b-col md="6">
             <ValidationProvider #default="validationContext" :name="$t('data.firstNmae')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.firstNmae')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="person"></b-icon>
                   <input
                       class="form-control"
                       v-model="clientModel.first_name"
                       @input="toUpperCaseFirstname"
                   />
                   <span class="text-danger">
                    {{ validationContext.errors[0] }}
                  </span>
                 </div>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Client lastName  -->
           <b-col md="6">
             <ValidationProvider #default="validationContext" :name="$t('data.lastName')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.lastName')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="person"></b-icon>
                   <input
                       class="form-control"
                       v-model="clientModel.last_name"
                       @input="toUpperCaseLastname"
                   />
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Client birthday  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.date_birthday')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.date_birthday')}}
                 </p>
                 <div class="wrapper-form">
                   <!-- <b-icon icon="calendar2-check"></b-icon> -->
                   <input type="date" class="form-control" v-model="clientModel.birthdate">
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>

           <b-col md="4">
             <div class="wrapper">
               <p class="wrapper-title">
                 {{$t('data.file')}}
               </p>
               <div class="wrapper-form">
                 <b-icon icon="file-earmark"></b-icon>
                 <input type="file" class="form-control" @change="fileChange">
               </div>
             </div>
           </b-col>

           <!-- Client mobile number  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext"  :name="$t('data.mobile_number')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.mobile_number')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="telephone"></b-icon>
                   <b-form-input v-model="number1" v-mask="'############'" type="text"></b-form-input>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Client mobile number additional -->
           <b-col md="4">
             <div class="wrapper">
               <p class="wrapper-title">
                 {{$t('data.mobile_number_additional')}}
               </p>
               <div class="wrapper-form">
                 <b-icon icon="telephone"></b-icon>
                 <b-form-input v-model="number2" type="text" v-mask="'############'"></b-form-input>
               </div>
             </div>
           </b-col>
         </b-row>
       </b-card>

       <b-card class="mt-2">
         <!-- Tour  -->
         <b-row>
           <b-col md="12">
             <h4>
               {{$t('message.tour_text')}}
             </h4>
           </b-col>
           <!-- Tour from_country_id  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.whence_region')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.whence_region')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="arrow-up-right-square-fill"></b-icon>
                   <v-select
                       v-model="dataModel.from_country_id"
                       :options="formOptions"
                       label="name_ru"
                       :pushTags="true"
                       taggable
                       @search="searchFromOptions"
                       @search:blur="changeFromCountry"
                       @option:selected="fromCountrySelected"
                   ></v-select>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Tour to_country_id  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.where_region')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.where_region')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="arrow-down-right-square-fill"></b-icon>
                   <v-select
                       v-model="dataModel.to_country_id"
                       :options="toOptions"
                       label="name_ru"
                       taggable
                       @search="searchToOptions"
                       @search:blur="changeToCountry"
                       @option:selected="selectedToCountry"
                   ></v-select>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Tour client_source_id  -->
           <b-col md="4">
             <div class="wrapper">
               <p class="wrapper-title">
                 {{$t('data.source_clients')}}
               </p>
               <div class="wrapper-form">
                 <b-icon icon="person-lines-fill"></b-icon>
                 <v-select
                     v-model="dataModel.client_source_id"
                     taggable
                     label="name"
                     :options="tourSourcesClientOptions"
                     :pushTags="true"
                 >
                 </v-select>
               </div>
             </div>
           </b-col>
           <!-- Tout start_date  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.date_departure')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.date_departure')}}
                 </p>
                 <div class="wrapper-form">
                   <!-- <b-icon icon="calendar2-check"></b-icon> -->
                   <input type="date" class="form-control" v-model="dataModel.start_date">
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Tout end_date  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.date_leaving')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.date_leaving')}}
                 </p>
                 <div class="wrapper-form">
                   <!-- <b-icon icon="calendar2-check"></b-icon> -->
                   <input type="date" class="form-control" v-model="dataModel.end_date">
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Tour partner_id  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.partner')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.partner')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="person-badge"></b-icon>
                   <v-select
                       v-model="dataModel.partner_id"
                       label="name"
                       taggable
                       :options="partnerOptions"
                       :pushTags="true"
                   ></v-select>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Tour actual_price  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.total_sum_gross')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.total_sum_gross')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="wallet2"></b-icon>
                   <b-form-input v-model="dataModel.market_price" type="number"></b-form-input>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Tour market_price  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.total_sum')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.total_sum')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="wallet2"></b-icon>
                   <b-form-input v-model="dataModel.actual_price" type="number"></b-form-input>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
           <!-- Tour currency_id  -->
           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.payment_methods')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.payment_methods')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="wallet2"></b-icon>
                   <v-select
                       v-model="dataModel.currency_id"
                       :options="currencies"
                       label="name"
                   ></v-select>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>

           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.service_price')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.service_price')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="wallet2"></b-icon>
                   <b-form-input v-model="dataModel.service_price" type="number"></b-form-input>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>

           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.payment_type')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.payment_type')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="wallet2"></b-icon>
                   <v-select
                       v-model="dataModel.payment_type_id"
                       :options="paymentType"
                       label="name_ru"
                   ></v-select>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>

           <b-col md="4">
             <ValidationProvider #default="validationContext" :name="$t('data.request_number')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('data.request_number')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="file-binary"></b-icon>
                   <b-form-input v-model="dataModel.request_number" type="text"></b-form-input>
                 </div>
                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>

           <b-col md="4">
             <div class="wrapper">
               <p class="wrapper-title">
                 {{$t('data.number_of_adults')}}
               </p>
               <div class="wrapper-form">
                 <b-icon icon="person-fill"></b-icon>
                 <b-form-input v-model="dataModel.number_of_adults" type="number"></b-form-input>
               </div>
             </div>
           </b-col>

           <b-col md="4">
             <div class="wrapper">
               <p class="wrapper-title">
                 {{$t('data.number_of_children')}}
               </p>
               <div class="wrapper-form">
                 <b-icon icon="person-fill"></b-icon>
                 <b-form-input v-model="dataModel.number_of_children" type="number"></b-form-input>
               </div>
             </div>
           </b-col>

           <b-col md="4">
             <div class="wrapper">
               <p class="wrapper-title">
                 {{$t('data.is_legal_collection')}}
               </p>
               <div class="wrapper-form border-0" style="padding: 7px !important">
                 <b-form-checkbox
                     class="px-2"
                     id="checkbox-1"
                     v-model="dataModel.is_legal_collection"
                     :value="true"
                     :unchecked-value="false"
                 ></b-form-checkbox>
               </div>
             </div>
           </b-col>

           <b-col md="12">
             <ValidationProvider #default="validationContext" :name="$t('message.HotelName')" rules="required">
               <div class="wrapper">
                 <p class="wrapper-title">
                   {{$t('message.HotelName')}}
                 </p>
                 <div class="wrapper-form">
                   <b-icon icon="building"></b-icon>
                   <v-select
                       v-model="dataModel.hotel_id"
                       :options="hotelOptions"
                       label="name"
                       :pushTags="true"
                       taggable
                       @search="searchHotel"
                       @search:blur="changeHotel"
                       @option:selected="selectedHotel"
                   ></v-select>
                 </div>

                 <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
               </div>
             </ValidationProvider>
           </b-col>
         </b-row>
       </b-card>


       <b-row v-if="ticketBool">
         <b-col md="12">
           <b-card class="mt-2">
             <!-- Avia_sales  -->
             <b-row>
               <b-col md="12">
                 <h4>
                   {{$t('message.avia_sales_text')}}
                 </h4>
               </b-col>
               <b-col md="12">
                 <ValidationProvider #default="validationContext" :name="$t('data.date_departure')" rules="required">
                   <div class="wrapper">
                     <p class="wrapper-title">
                       {{$t('message.Title')}}
                     </p>
                     <input type="text" v-model="dataTicket.name" class="form-control">
                     <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                   </div>
                 </ValidationProvider>
               </b-col>
               <!-- Avia_sales actual_price -->
               <b-col md="6">
                 <ValidationProvider #default="validationContext" :name="$t('data.sum_gross_air')" rules="required">
                   <div class="wrapper">
                     <p class="wrapper-title">
                       {{$t('data.sum_gross_air')}}
                     </p>
                     <div class="wrapper-form">
                       <b-icon icon="wallet2"></b-icon>
                       <b-form-input v-model="dataTicket.market_price" type="number"></b-form-input>
                     </div>
                     <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                   </div>
                 </ValidationProvider>
               </b-col>
               <!-- Avia_sales market_price -->
               <b-col md="6">
                 <ValidationProvider #default="validationContext" :name="$t('data.sum_total_air')" rules="required">
                   <div class="wrapper">
                     <p class="wrapper-title">
                       {{$t('data.sum_total_air')}}
                     </p>
                     <div class="wrapper-form">
                       <b-icon icon="wallet2"></b-icon>
                       <b-form-input v-model="dataTicket.actual_price" type="number"></b-form-input>
                     </div>
                     <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                   </div>
                 </ValidationProvider>
               </b-col>
               <!-- Tickets ticket_number  -->
               <b-col md="6">
                 <ValidationProvider #default="validationContext" :name="$t('data.ticket_number')" rules="required">
                   <div class="wrapper">
                     <p class="wrapper-title">
                       {{$t('data.ticket_number')}}
                     </p>
                     <div class="wrapper-form">
                       <b-icon icon="aspect-ratio-fill"></b-icon>
                       <b-form-input v-model="dataTicket.ticket_number"></b-form-input>
                     </div>
                     <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                   </div>
                 </ValidationProvider>
               </b-col>

               <!-- Tickets booking_number  -->
               <b-col md="6">
                 <ValidationProvider #default="validationContext" :name="$t('data.booking_number')" rules="required">
                   <div class="wrapper">
                     <p class="wrapper-title">
                       {{$t('data.booking_number')}}
                     </p>
                     <div class="wrapper-form">
                       <b-icon icon="file-binary"></b-icon>
                       <b-form-input v-model="dataTicket.order_number"></b-form-input>
                     </div>
                     <span class="text-danger">
                      {{ validationContext.errors[0] }}
                    </span>
                   </div>
                 </ValidationProvider>
               </b-col>
             </b-row>
           </b-card>
         </b-col>

         <b-col md="12">
           <b-card class="mt-2">
             <div class="d-flex">
               <p class="">
                 {{$t('data.whence_region')}}
               </p>
               /
               <p class="">
                 {{$t('data.where_region')}}
               </p>
             </div>
             <CountriesLine :timelineCountries="timelineCountries" @removeCountry="removeCountry" />
             <div class="w-100 mt-2">
               <v-select
                   :options="countries"
                   label="name_ru"
                   v-model="selectedCountry"
                   :pushTags="true"
                   taggable
                   @search="searchCountry"
                   @option:selected="selectedCountryOptions"
                   @search:blur="selectTempCountry"
               ></v-select>
             </div>
             <div class="w-100 d-flex justify-content-end mt-1">
               <b-button variant="primary" @click="addCountry" :disabled="selectedCountry === null">
                 <b-icon icon="plus"></b-icon>
               </b-button>
             </div>
             <span class="text-danger mt-2" v-if="timelineCountries.length === 0">
              {{$t('message.errrCountries')}}
            </span>
           </b-card>
         </b-col>
       </b-row>

       <div class="d-flex w-100 py-2">
         <b-button class="ml-auto" :variant=" ticketBool ? 'danger' : 'success' " @click="toggleTicket">
           <b-icon v-if="ticketBool" icon="dash"></b-icon>
           <b-icon v-else icon="plus"></b-icon>
           {{$t('message.avia_sales_text')}}
         </b-button>

         <b-button class="ml-2" :disabled="disabledItem" variant="primary" @click="handleAdd">
           {{$t('message.Add')}}
         </b-button>
       </div>
     </ValidationObserver>
   </b-overlay>
  </div>
</template>

<script>
  import HotelsForm from '@/views/directories/hotels/components/HotelsForm'
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import {required,alphaNum} from '@validations';
  import {getClients} from '@/api/clients/clients';
  import {addTours, getTourById, editTours} from '@/api/tours/index';
  import {getCountries} from '@/api/countries/countries';
  import {getHotels} from '@/api/hotels/index';
  import {getPartners} from '@/api/partners/index';
  import {mapActions,mapGetters} from 'vuex';
  import {getClientSources} from '@/api/source_client/source_client'
  import CountriesLine from '@/views/directories/clients/components/avia-tickets/CountriesLine';
  import moment from 'moment'


  export default {
    name: "Tours-ToursAdd",
    components: {
      ValidationProvider,
      ValidationObserver,
      CountriesLine,
      HotelsForm
    },
    data() {
      return {
        loading: false,
        required,
        alphaNum,
        ticketBool: false,
        number1: null,
        number2: null,
        disabledItem: false,
        file: null,
        clientModel: {
          id: null,
          first_name: '',
          last_name: '',
          birthdate: null,
          passport_serial: '',
          passport_number: '',
          given_date: null,
          expire_date: null,
          contacts: [{number: ''}, {number: ''}]
        },
        dataModel: {
          id: null,
          client_id:null,
          from_country_id: null,
          to_country_id: null,
          client_source_id: null,
          start_date: null,
          end_date: null,
          partner_id: null,
          actual_price: null,
          market_price: null,
          currency_id: null,
          hotel_id: null,
          service_price: '100000',
          payment_type_id: null,
          is_legal_collection: true,
          request_number: null,
          number_of_adults: null,
          number_of_children: null,
        },
        dataTicket: {
          actual_price: null,
          market_price: null,
          id: null,
          countries: null,
          ticket_number: '',
          order_number: '',
          name: ''
        },
        tours: {
          file: null,
          client: {
            id: null,
            first_name: '',
            last_name: '',
            birthdate: null,
            passport_serial: '',
            passport_number: '',
            contacts: [{
                number: ''
              },
              {
                number: ''
              }
            ],
            given_date: null,
            expire_date: null,
          },
          data: {
            id: null,
            client_id:null,
            from_country_id: null,
            to_country_id: null,
            client_source_id: null,
            start_date: null,
            end_date: null,
            partner_id: null,
            actual_price: null,
            market_price: null,
            currency_id: null,
            hotel_id: null,
            service_price: '100000',
            payment_type_id: null,
            is_legal_collection: false,
            request_number: '',
            number_of_adults: null,
            number_of_children: null,
          },
          ticket: {
            actual_price: null,
            market_price: null,
            id: null,
            countries: null,
            ticket_number: '',
            order_number: '',
            name: ''
          }
        },
        regionOptions: [],
        timeout: null,
        tourOptions: [],
        timelineCountries: [],
        selectedCountry: [],
        formOptions: [],
        fromTimeout: null,
        toOptions: [],
        toTimeout: null,
        tourSourcesClientOptions: [],
        tourSourcesClientTimeout: null,
        partnerOptions: [],
        tourPartnerTimeout: null,
        hotelOptions: [],
        hotelTimeout: null,
        modalHotel: false,
        fisrtNameOptions: [],
        firstNameTimiout: null,
        lastNameOptions: [],
        lastNmaeTimeout: null,
        paymentTypeOptions: [],
        tempFirstname: '',
        tempLastname: '',
        tempFromCountry: '',
        tempToCountry: '',
        tempHotel: '',
        tempSourcesClient: '',
        tempPartner: '',
        tempPaymentMethod: '',
        tempPaymentType: '',
        tempCurrency: '',
        tempCountries: '',
        countries: [],
        countryTimeout: null,
        dataTimeout: null,
      }
    },
    methods: {
      ...mapActions('settings', ['fetchCurrencies', 'fetchMethods']),
      toBack() {
        this.$router.go(-1)
      },
      handleAdd() {
        const isValid = this.$refs.refFormObserver.validate();
        if (isValid) {
          this.loading = true
          // disabled button
          this.disabledItem = true;
          setTimeout(() => {
            this.disabledItem = false;
          this.loading = false
          }, 1000)

          // client
          if(!this.$route.params.id) {
            this.tours.client.id = this.clientModel.id;
            this.tours.client.contacts = [];
            this.tours.client.contacts.push({number:this.number1},{number:this.number2})
            this.tours.client.contacts = JSON.stringify(this.tours.client.contacts)
            this.tours.client.first_name = this.clientModel.first_name
            this.tours.client.last_name = this.clientModel.last_name;
            this.tours.client.birthdate = moment(this.clientModel.birthdate).format('YYYY-MM-DD')
            this.tours.client.passport_serial = this.clientModel.passport_serial
            this.tours.client.passport_number = this.clientModel.passport_number
            this.tours.client.given_date = moment(this.clientModel.given_date).format('YYYY-MM-DD')
            this.tours.client.expire_date = moment(this.clientModel.expire_date).format('YYYY-MM-DD')
          } else {
            this.tours.client = {};
            this.tours.client.id = this.$route.params.id
          }
          // tour
          this.tours.data.id = this.dataModel.id
          this.tours.data.client_id = this.dataModel.client_id
          this.tours.data.from_country_id = this.dataModel.from_country_id.id ? parseInt(this.dataModel.from_country_id.id) : this.dataModel.from_country_id.name_ru //select
          this.tours.data.to_country_id = this.dataModel.to_country_id.id ? parseInt(this.dataModel.to_country_id.id) : this.dataModel.to_country_id.name_ru //select
          this.tours.data.client_source_id = this.dataModel.client_source_id.id //select
          this.tours.data.start_date = moment(this.dataModel.start_date).format('YYYY-MM-DD HH:mm')
          this.tours.data.end_date = moment(this.dataModel.end_date).format('YYYY-MM-DD HH:mm')
          this.tours.data.partner_id = this.dataModel.partner_id.id //select
          this.tours.data.actual_price = this.dataModel.actual_price
          this.tours.data.market_price = this.dataModel.market_price
          this.tours.data.currency_id = this.dataModel.currency_id.id //select
          this.tours.data.service_price = this.dataModel.service_price
          this.tours.data.payment_type_id = this.dataModel.payment_type_id.id //select
          this.tours.data.is_legal_collection = this.dataModel.is_legal_collection
          this.tours.data.hotel_id = this.dataModel.hotel_id.id ? parseInt(this.dataModel.hotel_id.id) : this.dataModel.hotel_id.name //select
          this.tours.data.request_number = this.dataModel.request_number;
          this.tours.data.number_of_adults =  this.dataModel.number_of_adults
          this.tours.data.number_of_children =  this.dataModel.number_of_children

          // formData
          let fd = new FormData();

          // ticket
          if(this.ticketBool) {
            this.tours.ticket.actual_price = this.dataTicket.actual_price
            this.tours.ticket.market_price = this.dataTicket.market_price
            this.tours.ticket.id = this.dataTicket.id
            this.tours.ticket.countries = this.dataTicket.countries
            this.tours.ticket.ticket_number = this.dataTicket.ticket_number
            this.tours.ticket.order_number = this.dataTicket.order_number
            this.tours.ticket.name = this.dataTicket.name
            fd.append('ticket', JSON.stringify(this.tours.ticket));
          }

          if(this.$route.params.tourId) {
            editTours(this.tours.data).then( () => {
              this.loading = false;
              this.toBack();
            })
          } else {
            fd.append('client', JSON.stringify(this.tours.client));
            fd.append('tour', JSON.stringify(this.tours.data));
            fd.append('passport_file', this.tours.file)

            addTours(fd).then( () => {
              this.loading = false
              this.toBack();
            })
          }
        }
      },
      fetchById() {
        if(this.$route.params.tourId) {
          getTourById(this.$route.params.tourId).then( response => {
            let res = response.data.data;
            this.dataModel.id = res.id;
            this.dataModel.client_id = parseInt(res.client_id)
            this.dataModel.from_country_id = res.from_country;
            this.dataModel.to_country_id = res.to_country
            this.dataModel.start_date = moment(res.start_date).format('YYYY-MM-DD')
            this.dataModel.end_date = moment(res.end_date).format('YYYY-MM-DD')
            this.dataModel.client_source_id = res.client_source
            this.dataModel.partner_id = res.partner
            this.dataModel.actual_price = parseInt(res.actual_price)
            this.dataModel.market_price = parseInt(res.market_price)
            this.dataModel.currency_id = res.currency
            this.dataModel.hotel_id = res.hotel
            this.dataModel.service_price = parseInt(res.service_price)
            this.dataModel.payment_type_id = res.payment_type
            this.dataModel.is_legal_collection = res.is_legal_collection
          })
        }
      },
      toggleTicket() {
        this.ticketBool = !this.ticketBool
      },
      searchFromOptions(item) {
        if (item.length > 0) {
          this.tempFromCountry = item;
          clearTimeout(this.fromTimeout);
          this.fromTimeout = setTimeout(() => {
            getCountries({
              search: item
            }).then(res => {
              this.formOptions = res.data.data.data
            })
          }, 500)
        }
      },
      changeFromCountry() {
        if(this.tempFromCountry) {
          if( this.dataModel.from_country_id === null ) {
            this.dataModel.from_country_id = {name_ru: this.tempFromCountry}
          }
        }
      },
      searchToOptions(item) {
        this.tempToCountry = item;
        if (item.length > 0) {
          clearTimeout(this.toTimeout);
          this.toTimeout = setTimeout(() => {
            getCountries({
              search: item
            }).then(res => {
              this.toOptions = res.data.data.data
            })
          }, 500)
        }
      },
      changeToCountry() {
        if(this.tempToCountry) {
          if(this.dataModel.to_country_id === null) {
            this.dataModel.to_country_id = {name_ru:this.tempToCountry, id: null}

          }
        }
      },
      selectedToCountry(item) {
        if(typeof item === 'string') {
          this.dataModel.to_country_id = {id: null, name_ru:item};
        }
        else {
          this.dataModel.to_country_id = item;
        }
      },
      fromCountrySelected(item) {
        if(typeof item === 'string') {
          this.dataModel.from_country_id = {id: null, name_ru:item};
        }
        else {
          this.dataModel.from_country_id = item;
        }
      },
      getClientSourcesOptions() {
        getClientSources().then( res => {
          this.tourSourcesClientOptions = res.data.data.data;
        })
      },
      getPartnersOptions() {
        getPartners().then(res => {
          this.partnerOptions = res.data.data.data;
        })
      },
      searchHotel(item) {
        if (item.length > 0) {
          this.tempHotel = item
          clearTimeout(this.hotelTimeout);
          this.hotelTimeout = setTimeout( () => {
            getHotels({search: item}).then( res => {
              this.hotelOptions = res.data.data;
            })
          }, 500)
        }
      },
      changeHotel() {
        if(this.tempHotel) {
          if(this.dataModel.hotel_id === null) {
            this.dataModel.hotel_id = {name: this.tempHotel}
          }
        }
      },
      selectedHotel(item) {
        if(typeof item === 'object') {
          this.dataModel.hotel_id = item
        } else if(typeof item === 'string') {
          this.dataModel.hotel_id = {name: item}
        }
      },
      fileChange(e) {
        this.file = e.target.files[0]
      },
      toLoware() {
        this.clientModel.passport_serial = this.clientModel.passport_serial.toUpperCase();
        if(this.clientModel.passport_serial.length >= 2) {
          this.$refs.passportNumber.focus();
        }
      },
      toUpperCaseFirstname() {
        this.clientModel.first_name = this.clientModel.first_name.toUpperCase();
      },
      toUpperCaseLastname() {
        this.clientModel.last_name = this.clientModel.last_name.toUpperCase();
      },
      addCountry() {
        if(typeof this.selectedCountry === 'string') {
          this.timelineCountries.push({
            name_ru: this.selectedCountry
          });
        }
        else {
          this.timelineCountries.push(this.selectedCountry);
        }

        this.selectedCountry = null;
      },
      removeCountry(index) {
        this.timelineCountries.splice(index, 1)
      },
      selectTempCountry(item) {
        if(this.tempCountries) {
          if(!this.selectedCountry) {
            // this.selectedCountry = this.tempCountries
            this.selectedCountry = {name_ru:this.tempCountries};
          }
        }
      },
      searchCountry(item) {
        if (item.length > 0) {
          this.tempCountries = item
          clearTimeout(this.countryTimeout);
          this.countryTimeout = setTimeout(() => {
            getCountries({
              search: item
            }).then(res => {
              this.countries = res.data.data.data
            })
          }, 500)
        }
      },
      selectedCountryOptions(item) {

      },
      changePassort() {
        if(this.clientModel.passport_serial.length > 0 && this.clientModel.passport_number.length > 0) {
          clearTimeout(this.dataTimeout);
          this.dataTimeout = setTimeout(() => {
            getClients({passport_serial: this.clientModel.passport_serial, passport_number: this.clientModel.passport_number}).then( response => {
              let res = response.data.data[0];
              this.clientModel.id = res.id;
              this.clientModel.first_name       = res.first_name;
              this.clientModel.last_name        = res.last_name;
              this.clientModel.birthdate        = res.birthdate;
              this.clientModel.passport_serial  = res.passport_serial;
              this.clientModel.passport_number  = res.passport_number;
              this.clientModel.given_date       = res.given_date;
              this.clientModel.expire_date      = res.expire_date;
               this.clientModel.contacts = JSON.parse(res.contacts);
              if (!this.clientModel.contacts[0]) {
                this.clientModel.contacts = new Array(this.clientModel.contacts)
              }
              if (this.clientModel.contacts.length === 1) {
                this.number1 = this.clientModel.contacts[0].number
              }
              if (this.clientModel.contacts.length === 2) {
                this.number1 = this.clientModel.contacts[0].number
                this.number2 = this.clientModel.contacts[1].number
              }
            })
          }, 400)
        }
      }
    },
    computed: {
      ...mapGetters('settings', ['currencies', 'paymentType']),
    },
    created() {
      this.fetchCurrencies().then(res =>{
        this.dataModel.currency_id= this.currencies.filter(item => item.name === 'usd')[0]
      }); //valyuta
      this.fetchMethods(); // payment methods;
      this.getPartnersOptions();
      this.getClientSourcesOptions();
      this.fetchById();
    },
  }
</script>
